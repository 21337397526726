import React from "react";
import { Col, Divider, Row, Typography } from "antd";

import Wrapper from "../../../components/Wrapper";

import "./our-business-component.scss";

import constructionImage from "../../../images/business/construction.jpg";
import miningImage from "../../../images/business/mining.jpg";
import factoryImage from "../../../images/business/factory.jpg";
import transportationImage from "../../../images/business/transportation.jpg";
import hotelImage from "../../../images/business/hotel.jpg";
import mallImage from "../../../images/business/mall.jpg";
import marineImage from "../../../images/business/marine.jpg";
import farmImage from "../../../images/business/farm.jpg";

import { useTranslation } from "gatsby-plugin-react-i18next";

const OurBusiness = () => {
  const { t } = useTranslation();

  return (
    <div className="our-business-component">
      <Wrapper>
        <Divider className="our-business-component__divider" orientation="left">
          <Typography className="our-business-component__title">
            Our Business
          </Typography>
        </Divider>
        <div>
          <div>
            <Typography
              className="our-business-component__text"
              style={{ fontSize: "18px", fontWeight: 800 }}
            >
              {t("ourBusiness.1.title")}
            </Typography>
            <Row>
              <Col>
                <Typography
                  className="our-business-component__text"
                  style={{ textAlign: "justify" }}
                >
                  {t("ourBusiness.1.description")}
                </Typography>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 20 }}>
            <Typography
              className="our-business-component__text"
              style={{ fontSize: "18px", fontWeight: 800 }}
            >
              {t("ourBusiness.2.title")}
            </Typography>
            <Row>
              <Col>
                <Typography
                  className="our-business-component__text"
                  style={{ textAlign: "justify" }}
                >
                  {t("ourBusiness.2.description")}
                </Typography>
              </Col>
            </Row>
          </div>
          <Row
            justify="space-between"
            style={{ marginTop: "20px" }}
            gutter={[16, 24]}
            align="middle"
          >
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={constructionImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("construction")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={miningImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("mining")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={factoryImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("factory")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={transportationImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("transportation")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={hotelImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("hotel")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="construction"
                  src={mallImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("mall")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="marine"
                  src={marineImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("marine")}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <div
                className="our-business-component__image-container"
                data-sal="fade"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <img
                  className="our-business-component__image-container__image"
                  alt="farm"
                  src={farmImage}
                />
                <div className="our-business-component__image-container__image__text">
                  {t("farm")}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </div>
  );
};

export default OurBusiness;

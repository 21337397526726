import React, { useState, useEffect } from "react";
import { Divider, Typography } from "antd";

import Wrapper from "../../Wrapper";

import useWindowSize from "../../../utils/useWindowDimensions";

import Slider from "react-slick";

import exxonImage from "../../../images/partners/exxon.webp";
import vopakImage from "../../../images/partners/vopak.webp";
import aggrekoImage from "../../../images/partners/aggreko.webp";
import ppUrbanImage from "../../../images/partners/pp-urban.webp";
import waskitaImage from "../../../images/partners/waskita.webp";
import wikaImage from "../../../images/partners/wika.webp";
import abiprayaImage from "../../../images/partners/abipraya.webp";
import hkImage from "../../../images/partners/hk.webp";
import deltaJakartaImage from "../../../images/partners/delta-djakarta.webp";
import ikmImage from "../../../images/partners/ikm.webp";
import kimImage from "../../../images/partners/kim.webp";
import repsolImage from "../../../images/partners/repsol.webp";
import arungImage from "../../../images/partners/arung.webp";
import radialImage from "../../../images/partners/radial.webp";
import uiImage from "../../../images/partners/ui.webp";

import "./partners.scss";

const Partners = () => {
  const [slickSettings, setSlickSettings] = useState({
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    mobileFirst: true,
  });

  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth > 1400) {
      setSlickSettings({
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        mobileFirst: true,
      });
    } else if (windowWidth > 800 && windowWidth < 1400) {
      setSlickSettings({
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        mobileFirst: true,
      });
    } else if (windowWidth < 800) {
      setSlickSettings({
        arrows: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
      });
    }
  }, [windowWidth]);

  const images = [
    repsolImage,
    exxonImage,
    ppUrbanImage,
    waskitaImage,
    wikaImage,
    hkImage,
    aggrekoImage,
    abiprayaImage,
    vopakImage,
    uiImage,
    deltaJakartaImage,
    ikmImage,
    kimImage,
    arungImage,
    radialImage,
  ];

  return (
    <div className="partners">
      <Wrapper>
        <Divider className="partners__divider" orientation="left">
          <Typography className="partners__title">
            Clients and Partners
          </Typography>
        </Divider>
        <div className="partners__content">
          <Slider {...slickSettings}>
            {images.map((item, index) => (
              <div key={index}>
                <img
                  className="partners__content__image"
                  alt="aggreko"
                  src={item}
                />
              </div>
            ))}
          </Slider>
        </div>
      </Wrapper>
    </div>
  );
};

export default Partners;

import React from "react";
import { Col, Divider, Row, Typography, Timeline } from "antd";

import Wrapper from "../../Wrapper";

import "./about-us-component.scss";

import { useTranslation } from "gatsby-plugin-react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us-component">
      <Wrapper>
        <Divider orientation="left" className="about-us-component__divider">
          <Typography className="about-us-component__title">
            About Us
          </Typography>
        </Divider>
        <div style={{ marginTop: "30px" }}>
          <Row justify="center" gutter={[48, 24]}>
            <Col md={24} lg={18}>
              <Typography
                style={{
                  color: "#ffffff",
                  textAlign: "justify",
                  fontSize: "16px",
                }}
              >
                {t("aboutUs-1")}
              </Typography>
              <Typography
                style={{
                  color: "#ffffff",
                  textAlign: "justify",
                  fontSize: "16px",
                  marginTop: "20px",
                }}
              >
                {t("aboutUs-2")}
              </Typography>
              <Typography
                style={{
                  color: "#ffffff",
                  textAlign: "justify",
                  fontSize: "16px",
                  marginTop: "20px",
                }}
              >
                {t("aboutUs-3")}
              </Typography>
            </Col>
            <Col md={24} lg={6}>
              <Timeline style={{ marginTop: "20px" }}>
                <Timeline.Item color="#f68a2e">
                  <Typography style={{ color: "#ffffff" }}>
                    Founded in 2018
                  </Typography>
                </Timeline.Item>
                <Timeline.Item color="#f68a2e">
                  <Typography style={{ color: "#ffffff" }}>
                    Licensed HSD Distributor 2021
                  </Typography>
                </Timeline.Item>
                <Timeline.Item color="#f68a2e">
                  <Typography style={{ color: "#ffffff" }}>
                    Official Repsol Industrial Lubricant Distributor 2023
                  </Typography>
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </div>
  );
};

export default AboutUs;

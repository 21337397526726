import * as React from "react";
import { Carousel, Typography, Button } from "antd";

import HeaderComponent from "../components/Header";

import "./index.scss";
import Wrapper from "../components/Wrapper";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import Pdf from "../pdf/company-profile.pdf";
import OurBusiness from "../components/Home/OurBusiness";
import AboutUs from "../components/Home/AboutUs";
import Partners from "../components/Home/Partners";
import FooterComponent from "../components/Footer";

import favicon from "../images/favicon.ico";

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

// markup
const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Prabu Cahaya Abadi | Penyalur BBM / Solar Industri dan Distributor
          Pelumas / Oli Industri Repsol”
        </title>
        <meta
          name="description"
          content="PT. Prabu Cahaya Abadi adalah perusahaan yang bergerak di bidang penyaluran BBM / Solar Industri dan Distributor Pelumas / Oli Industri Repsol yang telah memenuhi standar dan kriteria."
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <HeaderComponent />
      <Carousel autoplay={true}>
        <div>
          <StaticImage
            src="../images/banner/banner-1.jpg"
            alt="Banner"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div>
          <StaticImage
            src="../images/banner/banner-2.jpg"
            alt="Banner"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div>
          <StaticImage
            src="../images/banner/banner-3.jpg"
            alt="Banner"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div>
          <StaticImage
            src="../images/banner/banner-4.jpg"
            alt="Banner"
            placeholder="blurred"
            layout="constrained"
          />
        </div>
      </Carousel>
      <Wrapper>
        <div>
          <Typography className="home__companyName">
            Welcome to Prabu Cahaya Abadi Official Site
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              textAlign: "justify",
              fontSize: "18px",
            }}
          >
            {t("description")}
          </Typography>
          {/* <div className="home__downloadButton">
            <Button
              type="primary"
              href={Pdf}
              target="_blank"
              className="home__downloadButton__button"
            >
              <Typography className="home__downloadButton__button__text">
                Download our Company Profile
              </Typography>
            </Button>
          </div> */}
        </div>
      </Wrapper>
      <OurBusiness />
      <Partners />
      <AboutUs />
      <FooterComponent />
    </div>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["index", "about-us", "business"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
